@import "../../themes/hello-friend-ng/assets/scss/_variables";

.aside {
    max-width: 50%;
    @media #{$media-size-phone} {
        max-width: 100%;
    }
}

figure {
    &.right-floated, &.left-floated {
        @media #{$media-size-phone} {
            float: none;
            text-align: left;

            img {
                margin: 0 auto;
            }
        }
    }
}

ul.skills {
    display: grid;
    grid-gap: .25rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

#projects > .projects {
    @media print {
        display: grid;
        grid-gap: .35rem;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    @media screen {
        display: flex;

        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: black;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
    }

    .project {
        a {
            text-decoration: none;
        }
        @media print {
            display: inline-block;
            page-break-inside: avoid;
        }
        @media screen {
            scroll-snap-align: start;
            flex-shrink: 0;
            width: 300px;
            margin-right: 50px;
            padding: 0 0.5rem;
            border-radius: 10px;
            transform-origin: center center;
            transform: scale(1);
            transition: transform 0.5s;
            position: relative;
            justify-content: center;
            align-items: center;

            @media (prefers-color-scheme: dark) {
                background: $dark-background-secondary;
            }

            @media (prefers-color-scheme: light) {
                background: $light-background-secondary;
            }

            [data-theme=dark] & {
                background: $dark-background-secondary;
            }

            [data-theme=light] & {
                background: $light-background-secondary;
            }
        }
    }
}

section > h2 {
    line-height: 1.0;
}

#theater-contact {
    display: grid;
    grid-gap: .25rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

@media print {
	  .noprint { display: none; }
    .container > header { display: none; }
    .post-info { display: none; }
    footer { display: none; }
    #toc {
        display: none;
        & + hr { display: none; }
    }
    section > h2 {
        line-height: 0.75;
    }
}

figure.headshot {
    max-height: 12rem;
    margin: 0 auto;
}

ul.history {
    & > li:last-child {
        @media print {
            display: none;
        }
    }
}

@media screen {
	  .printonly { display: none; }
}
